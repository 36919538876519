const autoNgTemplateLoaderTemplate1 = require('./edgeDevicesView.html');

import angular from 'angular';

import { EdgeDevicesViewController } from './edgeDevicesViewController';

angular.module('portainer.edge').component('edgeDevicesView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EdgeDevicesViewController,
});
